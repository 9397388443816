#callus {
  background-color: #000;
  border-radius: 50%;
  /* bottom: 4.7rem; */
  top: 4.5rem;
  padding: 16px 19px;
  position: fixed;
  right: 1rem;
}
.popup {
  position: relative;
}

.popup:hover::after {
  content: "+918097259195";
  position: absolute;
  right:62px ;
  left: -10rem;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#whatsapp {
    background-color: green;
    border-radius: 50%;
    top: 8.5rem;
    /* bottom: 1.2rem; */
    padding: 9px 12px;
    position: fixed;
    right: 1rem;
}





.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}