body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.colorSection {
  background-color: #e6e6dc !important;
}

.whatsAppLink:hover {
  color: rgb(254, 189, 21);
}


.rig-tech-whatsapp-icon {
  
  display: flex;
  gap: 10px;
  align-items: flex-start;
  width: fit-content;
  background-color: #25d366;
  color: white;
  transition: background-color 0.3s ease-in;
 
}

.rig-tech-whatsapp-icon:hover {

  background-color: white;
  color:#25d366 ;
}
.Chat-On-WhatsApp{
  /* line-height: 0; */
  margin: 0;
  padding: 0;
  font-size: larger;
  font-weight: bold;
  align-self:center;
 
}
/* 

*/


.textAnimationDiv {
  font-family: 'Erica One', cursive;
  font-size: 26px;
  text-align: center;
  perspective: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textAnimationDiv span {
  display: inline-block;
  margin-left: -40px;
  color: #1eccf3;
  transform-origin: 50% 70%;
}

.textAnimationDiv span:nth-child(2n) {
  color: #ffc024;
}

.textAnimationDiv span:nth-child(1) {
  animation: flipper 1s cubic-bezier(0.68, -0.55, 0.26, 1.55) both, fadeIn 1s both;
}

.textAnimationDiv span:nth-child(2) {
  animation: flipper 1s cubic-bezier(0.68, -0.55, 0.26, 1.55) .2s both, fadeIn 1s both;
}

.textAnimationDiv span:nth-child(3) {
  animation: flipper 1s cubic-bezier(0.68, -0.55, 0.26, 1.55) .4s both, fadeIn 1s both;
}

.textAnimationDiv span:nth-child(4) {
  animation: flipper 1s cubic-bezier(0.68, -0.55, 0.26, 1.55) .6s both, fadeIn 1s both;
}

@keyframes flipper {
  from {
    transform: rotateX(90deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  ;

  to {
    opacity: 1
  }

  ;
}

.jodit-react-container a {
  color: black !important;
}





@media screen and (min-width: 990px) {
  .mobilemenustyle {
    display: flex;
    justify-content: space-evenly;
    flex: 2;
    align-items: center;
  }
}

@media screen and (max-width: 990px) {
  .mobilemenustyle {
    display: flex;
    justify-content: space-around;
    flex: 2;
    align-items: center;
    flex-direction: column;
  }

  .mobHeaderHome48 {
    margin: -48px
  }

  .mobHeaderHome23 {
    margin: -23px
  }

  .margin90 {
    margin-top: 10px;
  }

  .margin120 {
    margin-top: -120px;
  }

}


.zoom-in-out-box {
  /* margin: 24px; */
  width: 50px;
  height: 50px;
  /* background: #f50057; */
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* style={{ color: "#000", fontSize: "3rem", marginBottom: "0px", fontWeight: "600" }} */
.Header_zero {
  color: #000 !important;
  font-size: 3rem !important;
  margin-bottom: 0px !important;
  font-weight: 600 !important;
}

.service_video_class {
  height: 70vh;
}

@media screen and (max-width: 990px) {
  .Header_zero {
    color: #000 !important;
    font-size: 2rem !important;
    margin-bottom: 0px !important;
    font-weight: 600 !important;
  }

  .service_video_class {
    height: 40vh;
  }

  .mobile_header_height {
    height: 50vh;
    overflow: auto;
  }
}


.imageGallery {
  /* width: 120px;
  height: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid gray;
}

.AiFillCopy:hover {
  cursor: pointer;
}


/* .overlayColor{
  background-image: linear-gradient(-70deg,rgb(254,189,20,0.6)100%, rgb(254,189,21,0.0)56%);
} */


.input-Number::-webkit-outer-spin-button,
.input-Number::-webkit-inner-spin-button {
  display: none;
}