* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* cursor:url('../images/cursor.png'), auto; */
  /* cursor:url('../images/30.png'), auto; */
}

body {
  /* cursor: url("../images/30.png"), auto !important; */
}

.quaitybg77 {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/img212.jpg'); */
  background-size: cover;
  background-position: center;
  position: relative;
  height: 60vh;
  overflow: hidden;
}

.innerqualityup77 {
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.innerquality77 h4 {
  font-size: 1.5rem;
  font-weight: 700;
  opacity: 0.4;
}

.innerquality77 {
  position: absolute;
  top: 0px;
  left: -100%;
  right: 0px;
  bottom: 0px;
  background-color: rgba(254, 189, 21, 0.8);
  /* border: 2px solid #fff; */
  padding: 20px;
  height: 100%;
  width: 100%;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  transition: all 0.5s ease;
}

.quaitybg77:hover .innerqualityup77 {
  top: -300px;
}

.quaitybg77:hover .innerquality77 {
  left: 0;
}

.quaitybg77:hover .bottom77 {
  opacity: 0;
  transition: all 0.3s ease;
}

.btn1 {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  /* letter-spacing: .05rem; */
}

.btn1.btn-primary {
  background: #fcbc16;
  border-color: #fcbc16;
}

.section_bg {
  background-image: url("../images/section_bg.png");
  background-size: cover;
}

.upper_section {
  background-image: url("../images/toptop2.png");
  background-size: cover;
}

.why_bg {
  background-image: url("../images/why_bg.png");
  background-size: cover;
  background-position: bottom center;
}

.teacher_bg {
  background-image: url("../images/whyschool_bg2.png");
  background-position: bottom center;
}

.myspan {
  color: #fff !important;
}

i:hover {
  color: rgba(254, 189, 21, 0.8);
}

@media only screen and (max-width: 990px) {
  .why_bg {
    background-image: url("../images/miniwhy2.png");
    background-size: cover;
    background-position: bottom center;
  }

  .section_bg {
    background-image: url("../images/sectionbg_mini.png");
    background-size: cover;
  }

  .upper_section {
    background-image: url("../images/uppermini.png");
    background-size: cover;
  }

  .clintpx {
    width: 50% !important;
    height: 50% !important;
  }
}

@media only screen and (max-width: 768px) {
  .header_box {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f2732;
    position: relative;
    top: 0% !important;
  }

  .header_box h1 {
    font-size: 2rem;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
  }

  .header_box a {
    list-style: none;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
  }

  .nav_icon {
    width: 700px;
    display: flex;
    justify-content: space-around;
  }

  .header_box h1 {
    font-size: 1rem;
  }

  .header_box .nav_icon {
    display: flex;
    flex-direction: column;
    z-index: 99999;
    height: 100vh;
    background-image: url("../images/natura/navbar.svg");
    background-size: cover;
  }

  .menu_btn {
    display: block !important;
  }

  .nav_icon {
    position: absolute;
    top: 80px;
    left: -784px;
    width: 100%;
    height: 88vh;
    background-color: rgba(0, 0, 0, 0.78);
    text-align: center;
    transition: all 0.7s ease;
  }

  .mobile {
    position: absolute;
    top: 58px;
    left: 0px;
    width: 100% !important;
    height: 93vh;
    padding: 60px;
    background-color: #fff;
    z-index: 9999;
  }

  .mobile a {
    font-weight: 600;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
  }

  .nav_icon a {
    color: #fff !important;
  }
}

.header_box {
  height: 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0%;
  z-index: 3;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

li {
  list-style: none;
}

.header_box h1 {
  font-size: 1.6rem;
  font-weight: 300;
  color: #fcbc16;
}

.header_box a {
  list-style: none;
  text-decoration: none;
  color: #37423b;
  font-size: 0.9rem;
  font-weight: bold;
}

.header-content h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  white-space: 2px;
}

.header-content h2 {
  color: #eee;
  margin: 10px 0 0 0;
  font-size: 1.5rem;
  /* font-family: "Raleway", sans-serif; */
}

.nav_icon {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.menu_btn {
  display: none;
}

.btn.btn-sm {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn.btn-primary {
  background: #fcbc16;
  border-color: #fcbc16;
}

.btn {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  /* letter-spacing: .05rem; */
}

.logo {
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  left: 15px;
}

.video-play-btn {
  width: 90px;
  height: 90px;
  border: 2px solid #fcbc16;
  position: absolute;
  content: "";
  border-radius: 50%;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

a {
  color: #ffffff;
  text-decoration: none;
}

.bg {
  height: 100vh;
  /* background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; */
  /* background-image:linear-gradient(180deg, rgba(0, 0,0, 0.3), rgba(2, 0, 0, .5)), url(https://bootstrapmade.com/demo/templates/Mentor/assets/img/hero-bg.jpg) ; */
  /* background-image:linear-gradient(180deg, rgba(0, 0,0, 0.3), rgba(2, 0, 0, .5)), url('../images/digitalhand.jpg') ; */
}

.margin {
  margin-top: 140px !important;
}

.video-play-btn span {
  font-size: 20px;
  position: absolute;
  top: 36%;
  left: 43%;
  color: #fff;
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 600px) {
  .navbarlist {
    display: none;
  }

  .enrollbutton {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .bg-1 {
    width: 83%;
    height: 78%;
    right: 13%;
    top: -31px;
    z-index: -1;
    background: rgba(15, 183, 141, 0.2);
    position: absolute;
    border-radius: 12px;
  }
}

/* second section */
.block-v1 {
  position: relative;
  z-index: 8;
  padding: 30px;
  margin-right: 30px;
}

.icon {
  font-size: 35px;
  color: #ffffff;
  margin-bottom: 10px;
  display: block;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 40%;
}

.block-v1 {
  /* margin-top: 344px; */
  color: #ffffff;
}

.line-bottom {
  position: relative;
  padding-bottom: 10px;
  color: rgb(3, 45, 96);
}

.line-bottom::before {
  content: "";
  position: absolute;
  bottom: 0;
  padding-bottom: 3px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 150px;
  transform: translate(50%);
}

@media (max-width: 920px) {
  .line-bottom::before {
    content: "";
    position: absolute;
    bottom: 0;
    padding-bottom: 3px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 121px;
    transform: translate(50%);
  }

  .row_img img {
    width: 100% !important;
  }

  .foteer_img {
    /* width: 135px; */
    height: 70px;
  }

  .heade_logo {
    /* position: relative; */
    z-index: 1;
  }

  .Circular {
    width: 90vw !important;
  }
}

.bottom {
  left: 270px;
  position: absolute;
  width: 40px;
  height: 3px;
  background: #fcbc16;
  bottom: 0;
}

.Circular {
  width: 50vw;
}

.icon-class {
  font-size: 3rem;
  border-radius: 30%;
  padding: 18px 18px;
}

.bg-img {
  background-attachment: fixed;
}

.bg-img > .container {
  z-index: 2;
}

.bg-1 {
  width: 70%;
  height: 78%;
  right: -13%;
  top: -31px;
  z-index: -1;
  background: rgba(15, 183, 141, 0.2);
  position: absolute;
  border-radius: 12px;
}

.shadow-effect {
  margin-bottom: 30px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}

span {
  font-size: 10px;
}

.priceamount {
  font-size: 30px;
}

.price-section {
  text-align: center;
  padding: 20px;
  border: 1px solid #efefef;
  border-radius: 12px;
}

.bg-2 {
  position: absolute;
  height: 40%;
  width: 70%;
  left: -50%;
  top: 0px;
  z-index: -1;
  background: rgba(236, 102, 31, 0.1);
}

.footer {
  /* background: #032D60; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url("../images/natura/nfooter.jpg");
  background-position: center;
  background-size: cover;
  font-size: 14px;
  color: #ffffff;
  padding: 23px 0;
}

.courses {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: pointer;
}

.address {
  margin-bottom: 40px;
  display: block;
  z-index: 1;
}

.icons {
  height: 30px;
  width: 30px;
  background: white;
  align-items: center;
  color: black;
  justify-content: center;
  display: flex;
  border-radius: 50%;
}

/* navbar style */
.navbarbox {
  position: fixed;
  width: 100%;
  background-color: rgba(26, 24, 22, 0.85);
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 0;
  z-index: 1;
}

.navbar_trans {
  background-color: transparent;
  position: relative;
}

.navbar_colorChange {
  background-color: #000;
}

.vavcolor {
  color: red;
}

@media only screen and (max-width: 600px) {
}

.video-box {
  background-image: url(/src/images/client/contact_about.jpg);
}

.video-box1 {
  background-image: url(/src/images/playconatct.jpg);
  background-size: cover;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about-right {
  background-image: url(../images/service.jpg);
  background-size: cover;
  height: 60vh;
}

.about-right1 {
  background-image: url(../images/serviceschool1.jpg);
  background-size: cover;
  height: 60vh;
}

.about-right2 {
  background-image: url(../images/serviceschool2.jpg);
  background-size: cover;
  height: 60vh;
}

.about-right3 {
  background-image: url(../images/serviceschool3.jpg);
  background-size: cover;
  height: 60vh;
}

.about-right4 {
  background-image: url(../images/serviceschool6.jpg);
  background-size: cover;
  height: 60vh;
}

.about-right5 {
  background-image: url(../images/serviceschool5.jpg);
  background-size: cover;
  height: 60vh;
}

.about {
  background: #fffaf3;
}

.about .content {
  padding: 0 80px;
  align-items: center;
}

.about .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #5f5950;
}

.about .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.about .content p {
  font-size: 15px;
  color: #848484;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li + li {
  margin-top: 15px;
}

.about .content ul li {
  position: relative;
  padding-left: 26px;
}

.about .content ul i {
  font-size: 20px;
  color: #ffb03b;
  position: absolute;
  left: 0;
  top: 2px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .video-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  position: relative;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#ffb03b 50%, rgba(255, 176, 59, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 176, 59, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #ffb03b;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@media (max-width: 1024px) {
  .about .content,
  .about .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .about .content {
    padding-top: 30px;
  }

  .about .accordion-list {
    padding-bottom: 30px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* Counter=========== */

.countsold {
  background-color: #febd15;
}

.countsold span {
  font-size: 3rem;
  color: #fff;
  font-weight: bolder;
}

.countsold p {
  font-size: 1rem;
  color: #fff;
  font-family: 300;
}

/* Counter=========== */

/* Why Us------- */
/* # Whu Us */
/* --------------------------------------------------------------*/
#why-us {
  background-color: #656565;
  overflow: hidden;
}

.why-us {
  /* background-color: #656565; */
  overflow: hidden;
}

.why-us .box {
  /* padding: 50px 30px;
  background-color: #f3742a;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
  height: 100%; */
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #fff !important;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #6c665c;
}

.why-us .box p {
  color: #fff;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box ul li {
  list-style: none;
}

.why-us .box ul li i {
  color: #f3742a;
}

.why-us .box:hover ul li,
.why-us .box:hover ul li i {
  color: #febd15 !important;
}

.why-us .box:hover {
  background: RGB(233, 113, 45, 0.8);
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box:hover span,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
}

.section-title h2 span {
  color: #febd15 !important;
  font-size: 2rem;
}

.section-title h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #000000;
}

/* Why Us------- */

/* Client Card=== */
/*client_card===========*/

.card_wraper_box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /*    padding: 30px;
  */
}

.card_wraper_box .card {
  position: relative;
  max-width: 300px;
  height: 215px;
  background: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  /*        box-shadow: 0.5px 202px #fff;
  */
  transition: 0.3s ease-in-out;
}

.card_wraper_box .card:hover {
  height: 100%;
}

.card_wraper_box .card .imgBx {
  position: relative;
  width: 230px;
  /* height: 260px; */
  top: -60px;
  left: 20px;
  z-index: 1;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.card_wraper_box .card .imgBx img {
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
}

.card_wraper_box .card .content {
  position: relative;
  margin-top: -140px;
  padding: 5px;
  text-align: center;
  color: #111;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card_wraper_box .card:hover .content {
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.3s;
}

.imgBx img {
  padding: 20px;
  border: 2px solid #f5a228;
}

/* =================== */

/*client_card===========*/
/* Client Card=== */

/* Reviw--- */

.testim {
  width: 100%;
  position: relative;
}

.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 40px 20px;
  margin: auto;
}

.testim .arrow {
  display: block;
  position: absolute;
  color: #ed1e22;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 2;
}

.testim .arrow:before {
  cursor: pointer;
}

.testim .arrow:hover {
  color: #ea830e;
}

.testim .arrow.left {
  left: 10px;
}

.testim .arrow.right {
  right: 10px;
}

.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: block;
  z-index: none;
  height: 12px;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #eb2025;
  border-color: #ea830e;
}

.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
  z-index: 1;
}

.testim .cont {
  position: relative;
  overflow: hidden;
}

.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 70px 0;
  opacity: 0;
}

.testim .cont > div.inactive {
  opacity: 1;
}

.testim .cont > div.active {
  position: relative;
  opacity: 1;
}

.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}

.testim .cont div h2 {
  color: #eb2025;
  font-size: 1em;
  margin: 15px 0;
}

.testim .cont div p {
  font-size: 1.15em;
  color: rgba(2, 2, 2, 0.2);
  width: 80%;
  margin: auto;
}

.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}

.testim .cont div.active h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}

.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}

.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}

.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}

.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}

@media all and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }

  .testim .cont div p {
    line-height: 25px;
  }
}

/*BRevies===*/

.modal {
  position: fixed;
  top: 8%;
  /* transform: translate(50%); */
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.fade {
  transition: all 0.15s linear !important;
}

.fade:not(.show) {
  opacity: 0;
}

/* About page==== */
.about_banner {
  background-image: url("/src/images/aboutbanner.jpg");
  background-size: cover;
  height: 80vh;
  position: relative;
}

.rgba {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

/* About page==== */

/* career------ */
.career {
  background-image: linear-gradient(
      rgba(37, 37, 37, 0.224),
      rgba(0, 0, 0, 0.155)
    ),
    url("../images/natura/teambanner.svg");
  background-size: cover;
  background-position: right;
  height: 100vh;
  position: relative;
}

@media (max-width: 920px) {
  .career {
    height: 100%;
    background-position: center;
  }

  .career .container {
    background-color: transparent !important;
  }

  .team h2 {
    font-size: 1rem;
  }
}

/* career------ */

/* Abous Page */
.left_banner {
  height: 60vh;
  background-image: url(/src/images/vision.jpg);
  background-size: cover;
}

.right_banner {
  height: 60vh;
  background-image: url(/src/images/services_requird.jpg);
  background-size: cover;
}

.left-img {
  background-image: url(/src/images/client/nishant.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

@media (max-width: 920px) {
  .left-img {
    height: 40vh;
  }

  .right_text {
    height: auto !important;
  }
}

.right_text {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(7, 54, 106, 0.9);
  color: #fff;
}

/* About banner--- */
.about_banner1 {
  /* background-image:linear-gradient(to right, rgba(10, 52, 74,0.8), rgba(162, 219, 228,0.6)),url(/src/images/banner.jpg); */
  /* background-image: linear-gradient(114deg,rgb(254,189,20)50%, rgb(254,189,21,0.3)50%),url(/src/images/banner.jpg); */
  background-size: cover;
  background-position: center;
  height: 80vh;
  margin-top: 50px;
  /* border-bottom: 5px solid #002960; */
}

.about_banner1 h2 {
  font-size: 3rem;
}

.about_banner1 p {
  color: #fff;
}

.main_title_3 {
  text-align: center;
  margin-bottom: 45px;
  position: absolute;
  right: 0;
  top: 20%;
}

.main_title_3 span {
  width: 0;
  height: 2px;
  display: block;
  margin: 0;
}

.main_title_3 span em {
  width: 100px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(33, 36, 133);
  display: block;
  margin: auto;
}

/* About banner--- */

/* Lease---------- */
.belowheaddiv {
  padding: 30px 80px;
}

.belowheadsecdiv {
  border-right: violet 4px solid;
  padding: 30px;
}

.belowheadthirddiv {
  padding: 30px;
}

@media screen and (max-width: 990px) {
  .belowheaddiv {
    padding: 0px !important;
  }

  .belowheadsecdiv {
    border-right: none;
    padding: 30px;
  }
}

/* Lease---------- */

/* Home page new services--- */
/* cards=== */
.wrapper {
  display: block;
  margin-top: 100px;
  /* margin-left: 100px; */
  width: 435px;
  height: 260px;
  position: relative;
  padding: 10px;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/aboutshubham.jpg) fixed center center;
}

.hovercard {
  transform: none;
  transition: all ease 0.5s;
  width: 18rem;
  height: 55vh;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/service.jpg);
  background-size: cover;
}

.hovercard:hover {
  transform: translateY(3px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.hovercard1 {
  transform: none;
  transition: all ease 0.5s;
  width: 18rem;
  height: 55vh;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/service2.jpg);
  background-size: cover;
}

.hovercard1:hover {
  transform: translateY(3px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.hovercard2 {
  transform: none;
  transition: all ease 0.5s;
  width: 18rem;
  height: 55vh;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/service3.jpg);
  background-size: cover;
}

.hovercard2:hover {
  transform: translateY(3px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.hovercard3 {
  transform: none;
  transition: all ease 0.5s;
  width: 18rem;
  height: 55vh;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/service4.jpg);
  background-size: cover;
}

.hovercard3:hover {
  transform: translateY(3px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.hovercard4 {
  transform: none;
  transition: all ease 0.5s;
  width: 18rem;
  height: 55vh;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/service5.jpg);
  background-size: cover;
}

.hovercard4:hover {
  transform: translateY(3px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.hovercard5 {
  transform: none;
  transition: all ease 0.5s;
  width: 18rem;
  height: 55vh;
  background: linear-gradient(rgba(2, 2, 2, 0.2), rgba(0, 0, 0, 0.2)),
    url(/src/images/service6.jpg);
  background-size: cover;
}

.hovercard5:hover {
  transform: translateY(3px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

/* Home page new services--- */

/* down section---- */
.downsection {
  display: flex;
  height: 89vh;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(https://wordsleads.com/images/bg2.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
}

/* down section---- */

/* Why us--- */
.one {
  background: url(/src/images/client/one.jpg);
  background-size: cover;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
}

.two {
  background: url(/src/images/client/two.jpg);
  background-size: cover;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
}

.three {
  background: url(/src/images/client/three.jpg);
  background-size: cover;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
}

.four {
  background: url(/src/images/client/four.jpg);
  background-size: cover;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
}

.five {
  background: url(/src/images/client/five.jpg);
  background-size: cover;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
}

.six {
  background: url(/src/images/client/six.jpg);
  background-size: cover;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
}

/* bottom-banner--- */
.bottom-banner {
  height: 80vh;
  background-image: url(/src/images/callto.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* bottom-banner--- */

/* team------ */

.boxnew {
  border-radius: 150px;
  background: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.boxnew:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  border-radius: 150px;
  box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  background-image: linear-gradient(to right, #3c70a4 0%, #64b2cd 100%);
}

.boxnew:hover:before {
  width: 100%;
}

.boxnew:hover .image-wrapper {
  padding: 0;
}

.boxnew:hover .box-desc {
  color: #fff;
}

.boxnew:hover .social li a {
  background: #fff;
  background-image: none;
  color: #000;
}

.boxnew:hover .social li a:hover {
  background: #1d1d1d;
  color: #fff;
}

.image-wrapper {
  position: relative;
  max-width: 210px;
  max-height: 210px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  padding: 15px;
  transition: all 0.5s ease;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.image-wrapper img {
  border-radius: 50%;
  transition: all 500ms ease;
}

.box-desc {
  position: relative;
}

ul.social {
  padding: 0;
}

ul.social li {
  display: inline-block;
  list-style-type: none;
}

ul.social li a {
  position: relative;
  width: 36px;
  height: 36px;
  background-image: linear-gradient(to right, #3c70a4 0%, #64b2cd 100%);
  display: inline-block;
  line-height: 36px;
  border-radius: 50%;
  color: #fff;
  transition: all 0.5s ease;
}

/* team------ */

/* Home-services-- */
.shubham_card {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(/src/images/service6.jpg);
  height: 100%;
  background-size: cover;
}

.shubham_card1 {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(/src/images/aboutshubham.jpg);
  height: 100%;
  background-size: cover;
}

.shubham_card2 {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(/src/images/mission.jpg);
  height: 100%;
  background-size: cover;
}

/* Home-services-- */

.testim {
  width: 100%;
  position: relative;
}

.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 40px 20px;
  margin: auto;
}

.testim .arrow {
  display: block;
  position: absolute;
  color: #ed1e22;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 2;
}

.testim .arrow:before {
  cursor: pointer;
}

.testim .arrow:hover {
  color: #ea830e;
}

.testim .arrow.left {
  left: 10px;
}

.testim .arrow.right {
  right: 10px;
}

.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: block;
  z-index: none;
  height: 12px;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #eb2025;
  border-color: #ea830e;
}

.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
  z-index: 1;
}

.testim .cont {
  position: relative;
  overflow: hidden;
}

.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 70px 0;
  opacity: 0;
}

.testim .cont > div.inactive {
  opacity: 1;
}

.testim .cont > div.active {
  position: relative;
  opacity: 1;
}

.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}

.testim .cont div h2 {
  color: #eb2025;
  font-size: 1em;
  margin: 15px 0;
}

.testim .cont div p {
  font-size: 1.15em;
  color: rgba(2, 2, 2, 0.2);
  width: 80%;
  margin: auto;
}

.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}

.testim .cont div.active h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}

.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}

.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}

.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}

.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}

@media all and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }

  .testim .cont div p {
    line-height: 25px;
  }
}

/* ------------------New Home Page-----Natura---  */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #febd15;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dVideo {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  /* z-index: 1; */
}

/* .oneB:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 98%;
  width: 100%;
  z-index: 2;
  clip-path: polygon(50% 0%, 100% 0, 100% 80%, 50% 100%, 50% 100%, 0 80%, 0 0);
  background-color: rgba(0,0,0,0.3);
} */
/* ------------------New Home Page-----Natura---  */

/*--------------------------------------------------------------
# Whu Us
--------------------------------------------------------------*/
/* .why-us {
  background-color: rgba(7, 54, 106, 0.1);
} */

.mybox1 {
  position: relative;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/natura/fx.jpg");
  background-size: cover;
  background-position: center;
  transition: all ease-in-out 0.3s;
}

.why-us .mybox1:hover {
  background-image: linear-gradient(
      rgb(254, 189, 21, 0.6),
      rgb(254, 189, 21, 0.7)
    ),
    url("../images/natura/fx.jpg");
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.mybox2 {
  height: 100%;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/natura/Aerialtem.jpg");
  background-size: cover;
  background-position: center;
}

.why-us .mybox2:hover {
  position: relative;
  background-image: linear-gradient(
      rgb(254, 189, 21, 0.6),
      rgb(254, 189, 21, 0.7)
    ),
    url("../images/natura/Aerialtem.jpg");
  background-size: cover;
  background-position: center;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.mybox3 {
  position: relative;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/natura/Aerialtem2.jpg");
  background-size: cover;
  background-position: center;
}

.why-us .mybox3:hover {
  position: relative;
  background-image: linear-gradient(
      rgb(254, 189, 21, 0.6),
      rgb(254, 189, 21, 0.7)
    ),
    url("../images/natura/Aerialtem2.jpg");
  background-size: cover;
  background-position: center;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.mybox4 {
  position: relative;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/natura/adventureitem.jpg");
  background-size: cover;
  background-position: center;
}

.why-us .mybox4:hover {
  position: relative;
  background-image: linear-gradient(
      rgb(254, 189, 21, 0.6),
      rgb(254, 189, 21, 0.7)
    ),
    url("../images/natura/adventureitem.jpg");
  background-size: cover;
  background-position: center;
  padding: 30px 30px 70px 30px;
  /* padding: 30px 30px; */
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

/* .mybox5 {
  position: relative;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/natura/rigtechitem.jpg");
  background-size: cover;
  background-position: center;
} */

/* .why-us .mybox5:hover {
  position: relative;
  background-image: linear-gradient(
      rgb(254, 189, 21, 0.6),
      rgb(254, 189, 21, 0.7)
    ),
    url("../images/natura/rigtechitem.jpg");
  background-size: cover;
  background-position: center;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
} */

/* .why-us .box {
  position: relative;
  padding: 70px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
} */

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #212485;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #fff;
}

.why-us .box p {
  color: #fff;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover p {
  color: #000;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

/* .why-us .box:hover {
  background: #FEBD15;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
} */
.why-us .box:hover .btn {
  background: #fff !important;
  color: #febd15 !important;
  /* padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18); */
}

.why-us .box:hover span,
.why-us .box:hover h4 {
  color: #000;
}

.section-title h2 span {
  color: rgb(33, 36, 133);
}

.section-title h2 {
  /* margin: 15px 0 0 0; */
  font-size: 32px;
  font-weight: 700;
  color: #000000;
}

#servicebackgrounddiv {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.906)),
    url(http://zonalservices.com/Images/bgpegion.jpg);
  height: 100vh;
  background-position: center center;
  background-size: cover;
  transition: all 0.2s ease 0s;
}

#homeserviceswitchbtn {
  background-color: white;
  color: #f36c38;
}

.scroll_circle {
  position: absolute;
  height: 300px;
  width: 300px;
  background: red;
  transition: all 0.5s ease;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* =====Services---- */

#container {
  /* position: absolute;
    margin: auto;
    width: 100vw;
    height: 80pt;
    top: 0; */
  position: absolute;
  margin: 56px 0 0 0;
  width: 30%;
  height: 39pt;
  top: 10%;
  bottom: 0;

  filter: url(#threshold) blur(0.6px);
}

#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;
  color: #febd15;
  font-family: "Raleway", sans-serif;
  font-size: 12rem;

  text-align: center;

  user-select: none;
}

.section2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../images/natura/safety.jpg");
  height: 90vh;
  /* min-height: 100vh; */
  background-size: cover;
  background-position: center;
  /* background-color:#FEBD15; */
}

.Equipment-Safety {
  background-image: linear-gradient(
      rgba(31, 30, 30, 0.2),
      rgba(44, 42, 42, 0.304)
    ),
    url("../images/natura/safety.jpg");
  /* height: 90vh; */
  /* min-height: 100vh; */
  background-size: cover;
  background-position: center;
  /* background-color:#FEBD15; */
}
.Education-ConsultingImg {
  background-image: linear-gradient(
      rgba(24, 23, 23, 0.347),
      rgba(27, 26, 26, 0.502)
    ),
    url("https://natura.co.in//images/events/1677676886244_46167453_2217644074935925_8974090485727494144_n.jpg");
  /* height: 90vh; */
  background-attachment: fixed;
  /* min-height: 100vh; */
  background-size: cover;
  background-position: center;
  /* background-color:#FEBD15; */
}

.section2 b {
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 2px;
}

.section2 span {
  font-size: 1rem;
  font-weight: 900;
  color: #febd15;
}

.section2 h2 {
  font-size: 6rem;
  font-weight: 900;
  color: white;
  margin: -16px 0px;
}

.section3 {
  background-color: #febd15;
  height: 100vh;
  min-height: 100vh;
}

.section3 h2 {
  font-size: 3rem;
  font-weight: 700;
}

.section4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("https://www.riseworldwide.in/img/banners/1.jpg");
  height: 100vh;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}

.section4 h2 {
  font-size: 3rem;
  font-weight: 700;
}

.fillstudio {
  background-color: #414142;
}

.upper_head h2 {
  font-size: 7rem;
  font-weight: bolder;
}

.upper_head_about h2 {
  font-size: 3rem;
  font-weight: bolder;
}

.blacktext1::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -105%;
  background-color: #fff;
  width: 90%;
  height: 3px;
}

.swing {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: left;
}

@keyframes swing {
  0% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(-5deg);
  }
}

.swing2 {
  animation: swing2 ease-in-out 4s infinite;
  transform-origin: center -20px;
  float: left;
}

@keyframes swing2 {
  0% {
    translate: translateX(-300px);
  }

  100% {
    transform: translateX(2000px);
  }
}

.swing3 {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center 20px;
  float: center;
}

@keyframes swing3 {
  0% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(-5deg);
  }
}

.about_video_banner {
  background-image: url("../images/natura/rod.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 86px !important;
}

.home_safety_banner {
  background-image: url("../images/natura/rod.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.landing_video_banner {
  background-image: url("../images/homebanner.png");
  background-size: contain;
  background-repeat: no-repeat;
}

/* .curvy--- */

.client_box {
  position: relative;
  /* box-shadow: rgba(35,41,122, 0.4) 5px 5px, rgba(35,41,122, 0.3) 10px 10px, rgba(35,41,122, 0.2) 15px 15px, rgba(35,41,122, 0.1) 20px 20px, rgba(35,41,122, 0.05) 25px 25px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.client_box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* background-color: rgb(248,130,181,0.6); */
}

/* .curvy--- */

/* scroller-- */
/* Our Achievement---- */
/* Counter=========== */

.counts {
  /* background-color: #23297A; */
  background-color: #414142;
}

.counts span {
  font-size: 3rem;
  color: #fff;
  font-family: 300;
}

.counts p {
  font-size: 1rem;
  color: #fff;
  font-family: 300;
}

/* Counter=========== */

/* Our Achievement---- */

/* scroller-- */
/* .why-us .box1 {
  background-image: url('../images/service3.jpg');
  background-size:cover;
  transition: all ease-in-out 0.3s;
  height: 100%;
  position: relative;
  overflow: hidden;
} */
.lw {
  background-image: url("../images/natura/poster.png");
  height: 77vh;
  background-size: cover;
  background-position: center;
}

/* ---- */
/* DropDown Css----- */

/* Navbar DropDown==== */

.navbar-nav .service-submenu a {
  color: #fff !important;
  font-size: 0.8em;
  padding: 0.3em 0 0.3em 0em;
  text-align: start;
  text-decoration: none;
}

.header_box .nav_icon li .service-submenu a:hover {
  color: #fff !important;
}

.header_box .nav_icon li .service-submenu a {
  color: #fff !important;
  left: -100px;
  position: relative;
  font-size: 2rem;
  transform: rotate(-90deg);
}

.service-submenu p {
  color: #fff;
}

.service-submenu h6 {
  color: #ffff;
  border-bottom: 2px solid #fff;
  padding: 0.5em 0;
  /* margin: 0 0.5em; */
  text-transform: uppercase;
  text-align: left;
  font-size: 1.2em;
}

.service-submenu {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  padding: 15px;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 80%, 50% 100%, 50% 100%, 0 80%, 0 0); */
}

.service-submenu p {
  font-style: italic;
}

.service-menu {
  position: absolute;
  height: 0vh;
  top: 56px;
  left: 20%;
  transform: translateX(-20%);
  /* background: linear-gradient(rgba(2, 2, 2, 0), rgba(0, 0, 0, 0)), url(../component//images/navbarbg.png); */
  background-color: #febd15;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0.7em;
  z-index: 1;
  transition: all 0.7s cubic-bezier(1, 0, 0, 1);
  visibility: hidden;
  opacity: 0;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.315);
  clip-path: polygon(50% 0%, 100% 0, 100% 80%, 50% 100%, 50% 100%, 0 80%, 0 0);
}

.header_box .nav_icon li:hover .service-menu {
  visibility: visible;
  opacity: 1;
  height: 85vh;
  clip-path: polygon(50% 0%, 100% 0, 100% 80%, 50% 100%, 50% 100%, 0 80%, 0 0);
}

.ven_bg {
  transition: all 0.5s ease;
  position: relative;
}

.ven_bg:hover {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  border-radius: 10px;
}

.topbar {
  position: absolute;
  top: 0;
  left: 0;
}

.topbar .badge {
  clip-path: polygon(100% 0, 86% 50%, 100% 100%, 0 100%, 0 0);
}

/* Our Events--- */
.events {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; */
  background-color: #fff;
  /* background-image: linear-gradient(215deg, #9ad4d5 0%, #4492af 100%); */
  /* height: 100vh; */
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  justify-content: center;
}

figure.card {
  display: grid;
  grid-template: minmax(360px, 1fr) / minmax(280px, 1fr);
  grid-template-areas: "stack";
  /* max-height: 33vw; */
  border-radius: 0 0 1rem 1rem;
}

figure.card > img {
  /* border-radius: 1rem 1rem 0rem 0rem; */
  grid-area: stack;

  /* object-fit: cover; */
  /* place-self: stretch; */
}

figure.card > figcaption {
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  border-radius: 0 0 1rem 1rem;
  grid-area: stack;
  align-self: end;
  justify-self: stretch;
  background-color: #febd15;
  color: #fff;
  font-weight: 400;
  text-align: end;
  padding: 1.5rem;
  backdrop-filter: blur(6px);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  transition: background-color ease 0.3s;
  cursor: pointer;
}

figure.card > figcaption:hover,
figure.card > figcaption:focus {
  background-color: #febd15;
}

.reference {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 1rem;
}

.reference a {
  color: #fff;
}

/* Our Events--- */

.events_banner {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/natura/aerialacts.jpg");
  background-size: cover;
  background-position: center;
}

/* ___Natura Media-Query-- */
@media (max-width: 990px) {
  .bg {
    height: 100%;
  }

  .dVideo {
    height: 100%;
  }

  .block {
    height: 35vh;
  }

  .countsold span {
    font-size: 2rem;
  }

  .upper_head h2 {
    font-size: 3rem;
    font-weight: bolder;
  }

  .about_video_banner {
    height: 100% !important;
    background-size: auto;
  }

  .home_safety_banner {
    height: 100% !important;
    background-size: auto;
  }

  .upper_head_about h2 {
    font-size: 2rem;
    font-weight: bolder;
  }

  .swing {
    width: 100%;
  }

  .events_banner {
    background-position: initial !important;
  }

  .main_title_3 span {
    width: 100%;
    display: none;
    /* margin-bottom: 45px;
    position: absolute;
    right: 82px;
    top: 30%; */
  }

  .about_banner1 {
    background-position: initial;
  }

  .product_banner h1 {
    font-size: 3rem !important;
  }
}

.dropdown {
  transition: all 0.5s ease;
  /* transform: translateX(-60%); */
}

.dropdown-menu li i {
  font-size: 1rem;
  color: #fff;
}

.dropdown-menu img {
  height: 20px;
  width: 20px;
}

.dropdown-menu {
  margin-top: -70px;
  transition: all 0.2s ease;
  display: block;
  visibility: hidden;
  opacity: 0;
  width: 100%;
}

.dropdown-menu li a {
  color: #fff;
  font-size: 0.8rem;
}

.dropdown:hover .dropdown-menu {
  transition: all 0.3s ease;
  margin-top: -35px;
  border: none;
  visibility: visible;
  opacity: 1;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: #fcbc16;
}

.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* ___Natura Media-Query-- */

.topUpper {
  position: fixed;
  width: 101%;
  top: 0px;
  left: 0px;
  z-index: 6;
  background-color: rgb(3, 45, 96);
}

/* .headerinfo {
  position: fixed
    width: 100%;
    top: 0px;
    left: 0;
    z-index: ;,
    backgroundColor: rgb(3, 45, 96),
} */

@media only screen and (min-width: 480px) {
  .topUpper {
    position: relative;
    display: none;
    /* width: 101%;
    top: 0px;
    left: 0px;
    z-index: 6;
    background-color: rgb(3, 45, 96); */
  }
}

/* About us Accrodian */
.accordion-button:focus {
  background-color: rgb(254, 189, 21);
  border-color: rgb(254, 189, 21);
  box-shadow: none;
  color: #fff;
}

.accordion-button:hover {
  z-index: 0;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: rgb(254, 189, 21);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
  color: white;
  z-index: 1 !important;
  background: #646464;
}

.box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  transition: all ease-in-out 0.3s;
  height: 100%;
  border-radius: 13px;
}

.box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #fcbc16;
}

.box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #6c665c;
}

.box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

a:hover {
  color: rgb(255, 255, 255);
}

.core-features {
  position: relative;
  display: block;
  background-color: #656565;
  /* padding: 255px 0 120px;
  margin-top: -135px; */
}
.core-features-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.02;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .core-features__left {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
.core-features__left {
  position: relative;
  display: block;
  /* margin-right: 55px; */
}
.core-features__left .section-title {
  margin-bottom: 32px;
  text-align: left;
}
.core-features__left .section-title__tagline {
  color: #9e9ea7;
}
.section-title__tagline::before {
  position: relative;
  top: -2px;
  content: "";
  background-color: #fc653c;
  width: 6px;
  height: 6px;
  margin-right: 10px;
  border-radius: 50%;
}
.section-title__title {
  margin: 0;
  font-weight: 700;
  color: #1e1e22;
  font-size: 50px;
  line-height: 60px;
}
.core-features__left-bottom {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* .core-features__left-bottom-text {
  margin-left: 30px;
} */
/* .core-features__left-bottom-img > img {
  width: 170px;
  border-radius: var(--moniz-border-radius, 10px);
} */
/* For desktop screens */
.rigtech_image{
  padding: 1rem;
}
@media only screen and (min-width: 768px) {
  .rigtech_image {
    text-align: center;
  }

  .rigtech_image img {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    display: inline-block;
    max-height: 70vh; /* Set the maximum height to be 70% of the viewport height */
    /* width: 100%;
    object-fit: contain; */
  }
}

/* For mobile screens */
@media only screen and (max-width: 767px) {
  .rigtech_image img {
    width: 100%; /* Make the image take up 100% of the width of its container */
    display: block;
    margin: 0 auto; /* Center the image horizontally */
   
  }
}

.core-features__left-bottom-text p {
  color: #fcfcfc;
  margin: 0;
  text-align: left;
  font-size: 1.5rem;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .core-features__promicess {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .core-features__left-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.core-features__promicess-list {
  position: relative;
  display: block;
  z-index: 2;
}
.core-features__promicess-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #414142;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  z-index: 1;
}
.core-features__promicess-list li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--moniz-base-rgb, 255, 255, 255), 0.08);
  border-radius: var(--moniz-border-radius, 10px);
  z-index: -1;
}
.core-features__promicess-list li .text p {
  color: var(--moniz-base, #fffefe);
  font-weight: 700;
  margin: 0;
}
.core-features__promicess-list li .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.core-features__promicess-list li .icon {
  height: 50px;
  width: 50px;
  background-color: #febd15;
  border-radius: 50px;
  color: #ffffff;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

[class^="icon-"],
[class*=" icon-"] {
  /* font-family: 'icomoon' !important; */
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.core-features__left {
  position: relative;
  display: block;
  /* margin-right: 55px; */
}
@media (min-width: 1200px) {
  .core-features__left {
    position: relative;
    display: block;
    margin-right: 55px;
  }
  .core-features__left .section-title__tagline {
    color: #9e9ea7;
  }
  .section-title__tagline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #717178;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 30px;
    margin-bottom: 6px;
    font-weight: 700;
  }
  .core-features {
    position: relative;
    display: block;
    background-color: #656565;
    padding: 5%;
    /* margin-top: -135px; */
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.reasons__left {
  position: relative;
  display: block;
  margin-right: 70px;
}
.reasons__img {
  position: relative;
  display: block;
}
.reasons__img img {
  width: 90%;
  border-radius: var(--moniz-border-radius, 10px);
}
.section-title__tagline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: var(--moniz-gray, #717178);
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 30px;
  margin-bottom: 6px;
  font-weight: 700;
}
.section-title__tagline::before {
  position: relative;
  top: -2px;
  content: "";
  background-color: var(--moniz-primary, #fc653c);
  width: 6px;
  height: 6px;
  margin-right: 10px;
  border-radius: 50%;
}
.section-title__title {
  margin: 0;
  font-weight: 700;
  color: var(--moniz-black, #1e1e22);
  font-size: 50px;
  line-height: 60px;
}
.reasons__list {
  position: relative;
  display: block;
  margin-top: 40px;
}
.reasons__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.reasons__list li .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.reasons__list li .icon span {
  font-size: 20px;
  color: var(--moniz-primary, #fc653c);
}
.reasons__list li .text {
  margin-left: 15px;
}
.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: var(--moniz-primary, #fc653c);
  color: var(--moniz-base, #ffffff);
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px;
  -webkit-transition: 500ms;
  transition: 500ms;
  border-radius: 32px;
  overflow: hidden;
}

.reasons {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}
.ProcessBar {
  border-radius: 5px;
  padding: 8px;
}

.shape {
  background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
  animation: morph1 4s ease-in-out infinite;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  height: 70vh;
  transition: all 2s ease-in-out;
  width: 70vh;
  z-index: 5;
}
@keyframes morph1 {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    background: linear-gradient(45deg, var(--fourth) 0%, var(--five) 100%);
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    background: linear-gradient(45deg, var(--fourth) 0%, var(--five) 100%);
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    background: linear-gradient(45deg, var(--fourth) 0%, var(--five) 100%);
  }
}

.accordion-body {
  padding: 1rem 1.25rem !important;
  background: #646464 !important;
  color: white !important;
}

.info-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
  color: white !important;
}

.name-text {
  color: #fcbc16;
  /* text-align:start; */
}

#myEvent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.accordion-header span {
  font-size: 16px;
}

/* 
#longdec p:first-of-type:first-letter{
  font-size:1.2rem;
  font-weight: bold;
  float: left;
  display: inline-block;
  margin-top: 0.5rem;
  padding-right: 0.15rem;
  
  background-color: rgb(255, 208, 0);
  padding: 1rem 0.5rem;
  margin-right: 0.5rem;
} */


.linkstyle p a{
  color: #0d6efd !important;
}

.LOGO-SIZE{
  /* width:100%; */
  /* max-width: 50%; */
  height:90px
}

.headerdropdowntext{
  font-size: 0.8rem;
  /* padding-left: 10px; */
}



.serviceyoutubevideo{
  min-height: 70vh !important;
  height: 70vh;
}

@media (max-width:481px){
  .serviceyoutubevideo{
    min-height: 30vh !important ;
  }
}