/* .galleryheading h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
}

.galleryheading h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b8b500;
}

.galleryheading h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
} */

.creditbox{
    border-radius: 13px;
    /* box-shadow: 0 1px 6px hsl(0deg 79% 74% / 35%); */
    /* box-shadow: 0 1px 6px rgb(225 206 8); */
}


#maincontent img {
        max-width: 100%;
        height: auto;
}

#maincontent iframe {
    max-width: 100%;
    /* height: auto; */
}



.gallery {
    column-count: 3;
    --webkit-column-count: 3;
    --moz-column-count: 3;
    /* gap: 1rem; */
    padding: 2rem;
  }
  
  .single-image img {
    height: auto;
    width: 100%;
    margin: 0.2rem;
  }
  
  /* Responsive-ness for different screen-sizes */
  @media screen and (max-width: 810px) {
    .gallery {
      column-count: 3;
      --webkit-column-count: 3;
      --moz-column-count: 3;
    }
  }
  
  @media screen and (max-width: 500px) {
    .gallery {
      column-count: 2;
      --webkit-column-count: 2;
      --moz-column-count: 2;
    }
  }
  
  @media screen and (max-width: 400px) {
    .gallery {
      column-count: 1;
      --webkit-column-count: 1;
      --moz-column-count: 1;
    }
  }
  

  
