.blogs p{
    color: black !important;
}

.blogs a{
    color: black !important;
}

.blogs a:hover{
    color: rgb(13, 5, 247) !important;
}
.blogs ul{
    /* list-style: none; */
    list-style-type:disc; 
}

.blogs ul li{
    list-style-type:disc; 
}

.blogs img{
    max-width: 100%;
    height: auto;
}