/* 
  .main-container{
  background: #ffffff;
  background: linear-gradient(to bottom, #ffffff 0%,#f2e6c9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e1e8ed',GradientType=0 );
      height: 100vh;
      
          margin: 0;
          background-repeat: no-repeat;
          background-attachment: fixed;
    
  } */
 
  
  .wrapper-1{
    width:100%;
    height:100vh;
    display: flex;
  flex-direction: column;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
  }
 .wrapper-2 h1{
      /* font-family: 'Kaushan Script', cursive; */
    font-size:4em;
    letter-spacing:3px;
    color:#FEBD15 ;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .go-home{
    color:#fff;
    background:#FEBD15;
    border:none;
    padding:10px 50px;
    margin:30px 0;
    border-radius:30px;
    text-transform:capitalize;
    box-shadow: 4px 8px 40px 8px #e3c16d;
  }
  .footer-like{
    margin-top: auto; 
    background:#D7E6FE;
    padding:6px;
    text-align:center;
  }
  .footer-like p{
    margin:0;
    padding:4px;
    color:#FEBD15;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .footer-like p a{
    text-decoration:none;
    color:#FEBD15;
    font-weight:600;
  }
  
  @media (min-width:360px){
   .wrapper-2 h1{
      font-size:4.5em;
    }
    .go-home{
      margin-bottom:20px;
    }
  }
  
  @media (min-width:600px){
    .content{
    max-width:1000px;
    margin:0 auto;
  }
    .wrapper-1{
    height: initial;
    /* max-width:620px; */
    margin:0 auto;
    margin-top:50px;
    box-shadow: 4px 8px 40px 8px #e3c16d;
  }
    
  }