/*----  Main Style  ----*/
/* #cards_landscape_wrap-2{
    text-align: center;
    background: #F7F7F7;
  } */
  #cards_landscape_wrap-2 .container{
    /* padding-top: 80px;  */
    /* padding-bottom: 80px; */
  }
  #cards_landscape_wrap-2 a{
    text-decoration: none;
    outline: none;
  }
  #cards_landscape_wrap-2 .card-flyer {
    border-radius: 10px;
  }
  #cards_landscape_wrap-2 .card-flyer .image-box{
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
    /* border-radius: 5px; */
  }
  #cards_landscape_wrap-2 .card-flyer .image-box img{
    -webkit-transition:all .9s ease; 
    -moz-transition:all .9s ease; 
    -o-transition:all .9s ease;
    -ms-transition:all .9s ease; 
    width: 100%;
    height: 235px;
  }
  #cards_landscape_wrap-2 .card-flyer:hover .image-box img{
    opacity: 0.7;
    -webkit-transform:scale(1.15);
    -moz-transform:scale(1.15);
    -ms-transform:scale(1.15);
    -o-transform:scale(1.15);
    transform:scale(1.15);
  }
  #cards_landscape_wrap-2 .card-flyer .text-box{
    text-align: center;
  }
  #cards_landscape_wrap-2 .card-flyer .text-box .text-container{
    padding: 30px 13px 0px;
  }
  #cards_landscape_wrap-2 .card-flyer{
    background: #FFFFFF;
    /* margin-top: 50px; */
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
    border-radius: 10px;
  }
  #cards_landscape_wrap-2 .card-flyer:hover{
    background: #fff;
    /* box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50); */
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    /* margin-top: 50px; */
  }
  #cards_landscape_wrap-2 .card-flyer .text-box p{
    margin-top: 10px;
    margin-bottom: 0px;
    overflow: hidden;
    padding-bottom: 0px; 
    font-size: 14px;
    letter-spacing: 1px;
    color: #000000;
  }
  #cards_landscape_wrap-2 .card-flyer .text-box h6{
    margin-top: 0px;
    margin-bottom: 4px; 
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Roboto Black', sans-serif;
    letter-spacing: 1px;
    color: rgb(254, 189, 21);
  }



  .cardbtn {
    /* background: #ffd770; */
    border: 0;
    color: #fcbc16;
    width: 85%;
    font-weight: bold;
    border-radius: 10px;
    height: 40px;
    transition: all 0.2s ease;
}

.cardbtn:hover {
          background: #fcbc16;
          color: #fff;
}

.cardbtn:focus {
          background: #441CFF;
          outline: 0;  
}


.info-item i {
  font-size: 20px;
  color: #fff;
  float: left;
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  background-color: #fcbc16;
}

.info-item{
  /* width: 100%; */
    /* background-color: #009282; */
    /* margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    color: #fff; */
    padding: 10px 0px;
}

.info-item strong{
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

