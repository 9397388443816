#testimonialdata iframe{
	margin-left: auto;
	/* width: 37vw !important; */
	max-width: 100%;
    width: 100%;
	height: 52vh !important;
} 

#testimonialdata button{
    z-index: 1;
}

.testimonial-box {
    background-color: #000;
    color: #fff;
    box-shadow: 2px 2px 30px rgb(0 0 0 / 10%);
    cursor: pointer;
    /* margin: 15px; */
    padding: 20px;
    width: 100%;
    padding: 30px;
    /* width: 500px; */
    /* height: 370px; */
    height: 100%;
}