.card{
  background: white;
  border-radius: 4px;
  box-shadow: 0px 10px 10px #D9DBDF;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.card:hover {
  -webkit-transform: translate(20px, -10px);
  -ms-transform: translate(10px, -10px);
  transform: translate(10px, -10px);
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
}




.card-img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  
  .card-title {
    margin-bottom: 0.3rem;
  }
  
  .cat {
    display: inline-block;
    margin-bottom: 1rem;
  }
  
  .fa-users {
    margin-left: 1rem;
  }
  
  .card-footer {
    font-size: 0.8rem;
  }

  /* Pagination */

  .page-item.active .page-link {
    z-index: 0;
    color: #fff;
    background-color: #0c0c0c;
    border-color: #000;
}

.page-link {
  position: relative;
  display: block;
  color: #000000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.page-link:hover {
  z-index: 2;
  color: #000;
  background-color: #e9ecef;
  border-color: #dee2e6;
}