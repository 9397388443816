.environment-list {
  .items {
    display: flex;
    flex-direction: column;
    row-gap: 60px;

    .inner-content {
      column-gap: 2%;

      .left-section {
        width: 49%;
        overflow: hidden;
        border: 1px solid gold;
        border-radius: 5%;
        img {
          transition: all 0.5s ease-in-out;
          transform: scale(1);
        }

        &:hover {
          img {
            transform: scale(1.025);
          }
        }
      }

      .right-section {
        width: 49%;
        padding: 0px 0px 0px 120px;
      }

      .content {
        width: 90%;

        p {
          margin-top: 20px;
        }
      }

      .icon-wrapper {
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #9ec0e6;
        border-radius: 50%;
        margin-bottom: 30px;

        img {
          max-width: 80px;
          max-height: 80px;
        }
      }
    }

    .item {
      &:nth-child(even) {
        .left-section {
          order: 2;
        }

        .right-section {
          order: 1;
          padding: 0px 120px 0px 0px;
        }
      }

      &:last-child {
        padding-bottom: 75px;
        border-bottom: 15px solid var(--light-blue-color);
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .environment-list {
    .items {
      .inner-content {
        .icon-wrapper {
          width: 71px;
          height: 71px;
          margin-bottom: 10px;

          img {
            max-width: 42px;
            max-height: 42px;
          }
        }
        .right-section {
          padding: 30px 0px 0px 30px;
        }

        .content {
          width: 100%;

          p {
            margin-top: 10px;
            text-align: start;
          }
        }
      }

      .item:nth-child(even) {
        .right-section {
          padding: 30px 30px 0px 0px;
        }
      }

      .item:last-child {
        padding-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .environment-list {
    .md-heading {
      font-size: 1.15rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .environment-list {
    .items {
      row-gap: 40px;
      .inner-content {
        flex-direction: column;
        .left-section,
        .right-section {
          width: 100%;
        }

        .right-section {
          padding: 15px 0px 0px;
       
        
        }
      }
     

      .item {
        &:nth-child(even) {
          .right-section {
            padding: 10px 0px 0px;
            order: 2;
            text-align: right;

            .content {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
             
            }
          }
        }

        &:last-child {
          padding-bottom: 40px;
          border-width: 4px;
          position: relative;
        }
      }
    }
  }
}
