#safety h3{
    font-weight: 600;
    font-size: 26px;
    color: #001973;
    /* color: rgb(254, 189, 21); */
}

.fst-italic {
    font-style: italic!important;
}

#safety ul{
    list-style: none;
    padding: 0;
}

.text-primary-color{
    color:#001973
}